.card-icon {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -1.25rem;
  margin-right: -5px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  text-align: center;
  align-items: flex-end;

  flex: 0 0 48px;
  max-width: 48px;
  width: 48px;

  background: $primary;
  color: #f2ede9;

  .fas {
    vertical-align: middle;
  }
}
