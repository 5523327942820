$primary: #990000;
$secondary: #f2ede9;

$headingFont: 'Lato', sans-serif;
$accentFont: 'Lato', sans-serif;
$textColor: black;
$fa-font-path: '/assets/lib/font-awesome/webfonts';

@import "lib/bootstrap/scss/bootstrap";
@import "lib/font-awesome/scss/fontawesome";
@import "lib/font-awesome/scss/regular";
@import "lib/font-awesome/scss/solid";
@import "lib/font-awesome/scss/brands";

.btn {
  font-family: $accentFont;
  text-transform: uppercase;
  border-radius: 0;
  border-width: 2px;
  padding-left: 33px;
  padding-right: 33px;
}

.btn-outline-primary {
  border-color: #4d4641;
  color: #26211d;
}

// Typography

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headingFont;
  color: $textColor;
  font-weight: 400;

  strong {
    display: inline-block;
    font-family: $accentFont;
    border-bottom: 2px solid $primary;
    text-transform: capitalize;
    color: inherit;
    border-radius: 0;
    padding: 5px 0;
  }
}

.page-header {
  margin: 0 0 33px 0;
  text-align: center;
  border-bottom: none;

  h2, .h2 {
    margin-top: 15px;
    font-weight: 300;
    margin-bottom: 0;
  }
}

.fa-small {
  font-size: 0.75em;
}

// Features

/* Configure default responsiveness */
img {
  max-width: 100%;
  height: auto;
}

// Skinning

.carousel-control-next, .carousel-control-prev {
  max-width: 100px;
}

.carousel {

  color: white;

  h2 {
    margin-top: 20px;
    color: white;
  }

  h2 strong {
    border-bottom: none;
  }

}

.carousel-slide-header {
  font-family: $accentFont;
  font-size: 12px;
  border-radius: 0;
  color: white;
  border: 2px solid transparent;
  padding: 6px 12px;
  cursor: default;
  background-color: $primary;
  text-transform: uppercase;
}

.carousel-indicators-outside-image {
  padding-bottom: 40px;
}

.carousel-indicators-outside-image > .carousel-indicators {
  bottom: 0;
}

.carousel-inverted {

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23900' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23900' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  }

  .carousel-indicators li {
    background-color: $primary;
  }
}

.carousel-image {
  display: block;
}

// Skinning - Navbar
.navbar {
  padding-top: 5px;
  padding-bottom: 5px;

  font-family: "Lato", sans-serif;

  a {
    text-transform: uppercase;
  }

  ul.navbar-nav li:first-child > a {
    padding-left: 0;
  }

  @include media-breakpoint-only(sm) {
    .navbar-toggler {
      margin-left: $grid-gutter-width / 2;
    }
  }

}

.navbar-dark {

  .navbar-nav .nav-link {

    color: white;

    &:hover, &:active {
      color: $gray-800;
    }

  }

  .navbar-nav {

    .active > .nav-link, .show > .nav-link {
      color: $gray-500;
    }

  }

  .navbar-toggler {
    border-color: rgba(white, 0.5);
  }

  .dropdown-menu {
    background: darken($primary, 5%);
    padding: 0;

    .dropdown-item {
      color: $gray-200;

      &:hover, &:focus {
        background: darken($primary, 10%);
      }
    }
  }
}

// Skinning - Small
small {
  color: $gray-600;
  font-size: 60%;
}

// Skinning - Alert
.alert p:last-of-type {
  margin-bottom: 0;
}

// Clock
#clock {
  font: 300 40px/1 'Lato', sans-serif;
  color: #777;
}

// Custom components

@import "components/button";
@import 'components/card';
@import 'components/card-collapse';
@import 'components/card-header-clickable';
@import 'components/card-list';
@import 'components/dl-horizontal';
@import 'components/table-outline';
@import 'components/flipboard';

// Training Progress
$buttonBorderColor: #4d4641;
.training-progress {
  border: solid 1px $buttonBorderColor;
  border-left: none;
  border-bottom: none;
  margin-left: 0;
  margin-right: 0;

  .training-stages {
    border-left: solid 1px $buttonBorderColor;
    border-bottom: solid 1px $buttonBorderColor;
  }
}

.training-stages-title {
  font-weight: bold;
}

// Footer
.copyright {
  font-size: 15px;
  color: #fff;
  text-align: center;
  background: #2a2928;
  padding: 11px 0;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.page {
  flex: 1 0 auto;
}

footer {
  margin-top: 5px;
  flex-shrink: 0;

  padding: 0 0 0;
  background: #32302f;
}

// Breadcrumbs
.breadcrumb-section {
  background: url(/assets/img/breadcrumb-bg.jpg) center;
  background-size: cover;
  padding: 48px 0 39px;

  /* Add a stroke for readability */
  text-shadow: -1px -1px 0 #fff,
                1px -1px 0 #fff,
               -1px  1px 0 #fff,
                1px  1px 0 #fff;
}

.breadcrumb-section h1 {
  font-weight: 700;
  margin-bottom: 0;
}

.breadcrumb {
  background: none;
  border-radius: 0;
  padding: 4px 0;

  .active {
    font-weight: 700;
  }
}

.breadcrumb > li, .breadcrumb > .active, .breadcrumb a, .breadcrumb > li + li:before {
  color: #000000;
}

/* Iconography */

[class^="icon-"] {
  background: url(/assets/img/icons.png) no-repeat 0 0;
  display: inline-block;
  height: 14px;
  line-height: 14px;
  margin-top: 1px;
  vertical-align: text-top;
  width: 14px;
}

.icon-search {
  width: 24px;
  height: 24px;
}

.icon-volume {
  width: 37px;
  height: 36px;
  background-position: 0 -30px;
  margin-top: -5px;
}

.icon-user {
  width: 29px;
  height: 36px;
  background-position: 0 -69px;
  margin-top: -3px
}

.icon-heart {
  width: 38px;
  height: 36px;
  background-position: 0 -109px;
  margin-top: -3px;
}

.icon-embed {
  width: 20px;
  height: 25px;
  background-position: -40px -30px;
}

/* Media with Icon */

.media {
  font-size: 18px;
  margin-bottom: 33px;

  > .float-left.media-icon-box {
    margin-right: 28px;
  }
}

.media-icon-box {
  width: 68px;
  height: 68px;
  padding: 16px 0;
  text-align: center;
  background: $primary;
}

// Header
.primary-header {
  padding: 11px 0 11px;

  .brand, .align-items-end {
    max-height: 44px;
  }

  .brand img {
    max-height: 37px;
  }

  .social-icons {
    padding: 6px 0 0;

    a {
      color: #000000;
    }
  }

  @include media-breakpoint-only(md) {
    .col.align-items-end {
      flex-grow: 2;
    }
  }

}

.navbar-mobile {
  .social-links {

    display: flex;

    a {
      padding-top: 9px;
      color: $secondary;
    }
  }

  @include media-breakpoint-only(xs) {
    .social-icons > li:last-child {
      color: blue !important;
      margin-right: 0;
    }
  }
}

.social-links {
    display: inline-block;
}

.social-icons {
    list-style: none;
    margin-bottom: 0;
}

.social-icons img {
    max-height: 2em;
    margin-top: -0.3em;
}

.social-icons > li {
    float: left;
    margin-right: 18px;
}

.social-icons a {
    font-size: 20px;
    vertical-align: middle;
    line-height: 20px;
}

// Notam banner

.notam-banner {
  background-color: theme-color-level("info", $alert-bg-level);
  color: theme-color-level("info", $alert-color-level);

  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-bottom: 0;
  border-radius: 0;

  &.fixed {
    position: fixed;
    top: 0;
    z-index: 25;
    width: 100%;
  }

  p, p:last-of-type {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}

.notam-banner-spacer {
  display: none;
}

.notam-banner-spacer.fixed {
  display: block;
  height: 58px;
}

// Carousel Content
#welcome .carousel-inner {
  height: 100%;
}

#welcome {
  height: 450px;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  #welcome {
    height: 600px;
  }
}

#welcome .container {
  padding-top: 35px;
  padding-left: 54px;
  padding-right: 54px;
}

#welcome h2 {
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 24px;
}

@include media-breakpoint-up(md) {
  #welcome h2 {
    font-size: 36px !important;
  }
}

#welcome .carousel-item {
  height: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: 36%;
}

.welcome-1 {
  background-image: url("/assets/img/SlideBanner1.jpg");
}

.welcome-2 {
  background-image: url("/assets/img/SlideBanner3.jpg");
}

// Scenery list group
.scenery-store {
  img {
    height: 1em;
    vertical-align: text-bottom;
  }

  span {
    display: inline-block;
    height: 100%;
  }

  .fas {
    vertical-align: text-bottom;
  }
}

.scenery + small {
  font-size: 70%;
}

@include media-breakpoint-up(md) {
  .scenery-title {
    min-height: 4em;
  }

  .scenery {
    min-height: 270px;
    max-height: 270px;
    text-align: center;

    img {
      max-width: 100%;
      width: auto;
      height: 270px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .scenery-title {
    min-height: 3em;
  }
}

.atc-table {
  .cid {
    width: 24px;
  }

  .rating {
    width: 200px;
  }

  .validation {
    width: 250px;
  }

  .notes {
    width: 225px;
  }

  &.table th, &.table td {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
}
