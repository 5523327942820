.card-collapse {

  p:last-of-type {
    margin-bottom: 0;
  }

  /* Dynamic +/- icons */
  a .card-icon i.fa:before {
    content: '\f068';
  }

  a.collapsed .card-icon i.fa:before {
    content: '\f067';
  }
}

a.chevron-collapse {
  i.fas:before {
    content: '\f078';
  }
}

a.chevron-collapse.collapsed {
  i.fas:before {
    content: '\f054';
  }
}
