.card-list {

  .card {
    border: 0;
    border-radius: 0;
    margin-bottom: 0.75rem;
  }

  .card-header {
    border-bottom: 0;
    border-radius: 0;
  }

}
