// Styling for tables which draw's it like a tic-tac-toe grid.
.table.table-bordered.table-outline {

  border: 0;

  th {
    border-top: transparent;
  }

  th:first-of-type, td:first-of-type {
    border-left: transparent;
  }

  th:last-of-type, td:last-of-type {
    border-right: transparent;
  }

  tr:last-of-type > td {
    border-bottom: transparent;
  }

}

.table td.table-xs-btn {
  padding-top: 0.5rem;
  padding-bottom: 0;

  .btn.btn-fa.btn-sm {
    width: 2rem;
  }
}
