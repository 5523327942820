.flipboard-title {
  display: inline-block;
  vertical-align: top;
  padding-top: 0.75em;
  padding-right: 10px;
}


@include media-breakpoint-down(md) {
  .flipboard .char-container {
    transform: scale(0.5);
    width: 25px;
  }
}

.flipboard {

  .char-container {
    display: inline-block;
  }

  .char {
    display: block;
    background-image: url('/img/flip/chars.png');
    width: 50px;

    &.upper {
      height: 38px;
    }

    &.lower {
      height: 37px;
    }

    &.flip {
      display: inline-block;
      position: absolute;
      height: 38px;
      top: 0;
      z-index: 2 !important;

      transform-style: preserve-3d;
      transform-origin: bottom center 0;
      transform: rotateX(0deg);
      transition: transform .1s;
    }

    &.flip {

      &.flipping-start {
        transform: rotateX(90deg);
      }

      &.flipping-end {
        transform: rotateX(180deg);
        height: 37px;
      }

      &.flipping-hide {
        display: none;
      }
    }
  }
}
