.card-header-clickable {

  // Make the a span the entire header to improve click hitbox.
  &.card-header {
    padding: 0;
  }

  &.card-header a {
    padding: 0.75rem 1.25rem;
    display: block;
  }

  &.card-header div.download-unauthorized {
    color: $primary;
    padding: 0.75rem 1.25rem;
    display: block;
  }

}
